.copy-to-clipboard {
  border: 1px solid rgb(165, 165, 165);
  border-radius: 4px;
  width: fit-content;
  padding: 4px 8px;
  cursor: pointer;
}
.copy-to-clipboard:hover {
  background: rgb(165, 165, 165);
  color: white;
}
