.home-link { padding: 1.25rem 0 0 0; }

/* section: reset-link-sent */
#reset-link-sent {
    margin-top: 7em;
    margin-left: .5rem;
    margin-right: .5rem;
    height: 400px;
}

#reset-link-sent .reset-link-sent-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

#reset-link-sent .reset-link-sent-container h1 {
    padding-bottom: 1.25rem;
    color: rgb(39,25,58);
}

#reset-link-sent .reset-link-sent-container p {
    line-height: 2;
    text-align: center;
    color: rgb(114, 113, 113);
}

#reset-link-sent .reset-link-sent-container span {
    font-size: 3rem;
    padding-top: 1.25rem;
    color: rgb(39,25,58);
}