/* redish background for selected card */
.redish {
  background-color: rgb(233, 87, 126) !important;
  color: #fff !important;
}

/* save-button */
.save-button-home {
  text-transform: uppercase;
  position: fixed;
  top: 20%;
  transform: rotate(-45deg);
  background: rgb(34, 160, 182);
  padding: 0.25rem 0.75rem;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: 0.2s all;
  right: -5px;
}

.save-button-hide {
  display: none;
}

.save-button-home:hover {
  background: rgb(15, 210, 245);
}

/* hide-show-btn */
.hide-show-btn {
  font-size: 0.55rem;
  margin-left: 1.2rem;
  font-weight: 100;
  border: 1.5px solid goldenrod;
  padding: 0.25rem 0.5rem;
  border-radius: 10%;
  color: #555;
  cursor: pointer;
}

.hide-show-btn:hover {
  border: 1.5px solid rgb(228, 45, 45);
  color: rgb(216, 81, 81);
}
