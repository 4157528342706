@keyframes anim-forward {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.tip-popup {
  position: absolute;
  top: 30px;
  left: 54px;
  border: 3px solid #ff7e00;
  border-radius: 8px;
  background: #ffffff;
  padding: 2rem;
  z-index: 2;
  animation: 2.5s anim-forward forwards ease-in;
}

.tip-popup > h3 {
  padding-bottom: 0.5rem;
}

.tip-popup > span {
  color: #656363;
}

.close-popup {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.close-popup > .lnr-cross {
  font-size: 16px;
  cursor: pointer;
  color: #ff7e00;
}

.triangle {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 50px 0 0 150px;
  border-color: transparent transparent transparent #ff7e00;
  border-radius: 0 0 144% 0;
  position: fixed;
  top: -2px;
  right: -178px;
  transform: rotate(54deg) skew(52deg, 286deg) scale(0.4);
}
