@import url('https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@500&display=swap');

.search-result-container {
  display: flex;
  flex-direction: column;
}

.info-section {
  display: flex;
  background: #ffffff;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}

.result-section {
  justify-content: center;
  /* width: 100%; */
  /* max-width: 1280px; */
  /* padding: 0 10rem 1.25rem 10rem; */
  /* margin: auto; */
  /* border: 1px solid red; */
}

.products-section {
  /* width: 75%; */
}

.product-section-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5rem 0 0 0;
}

/* utility classes */
.greyish-text {
  color: #999;
}

.blackish-text {
  border-bottom: 1px solid #dad1c2;
}

.cursor-pointer {
  cursor: pointer;
  display: inline;
}

/* search-results */
#search-results {
  margin-top: 9em;
}

#search-results .search-results-container {
  padding: 0 0.75rem;
}

#search-results .header-text h1 {
  font-family: 'Anek Malayalam', sans-serif;
}

.product-item {
  display: flex;
  flex-direction: column;
  position: relative;
}

.product-item .in-stock {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #fff;
  border-radius: 25px;
  padding: 0.25rem 0.75rem;
  font-weight: bold;
}

.product-item > * {
  line-height: 2;
}

.product-item .title {
  font-size: 1.25rem;
  font-weight: bold;
}

.product-item .brand {
  text-decoration: underline;
}

/* for smaller devices */
@media screen and (max-width: 1365px) {
  .search-results-line {
    width: 98%;
  }
}

@media screen and (max-width: 750px) {
  .prod-items {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 650px) {
  #search-results .header-text h1 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 400px) {
  #search-results .header-text h1 {
    font-size: 1rem;
  }

  .product-item .title {
    font-size: 0.9rem;
  }

  .product-item .brand {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 960px) {
  .prod-items {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .prod-items {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 500px) {
  .prod-items {
    grid-template-columns: 1fr;
    text-align: center;
    line-height: 1.75;
  }
  .result-section {
    padding: 0 0;
    justify-content: center;
  }
  .product-section-header {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}
