header {
  width: 100%;
  max-width: 100%;
  position: sticky;
  top: 0;
  z-index: 3;
  padding: 0;
  background: transparent;
  transition: background 200ms ease-out 0s;
  max-height: 80px;
}

.header-scroll {
  background: #f9f9f9;
}

.header-scroll .menu-items {
  color: #000000;
}

.search {
  position: absolute;
  left: 50px;
}

.header-scroll .main-header .search > span {
  color: #000000;
}

.main-header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1.25rem 3rem;
}

.main-header .search > span {
  font-size: 1.5rem;
  color: #ffffff;
  cursor: pointer;
  vertical-align: bottom;
}

.main-header .search > span:hover {
  color: #d3d0d0;
}

.main-header .login > svg:hover {
  fill: #d3d0d0;
}

.menu-items {
  color: #ffffff;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
}

.menu-items:hover {
  color: #d3d0d0;
}

.saved-items-button {
  position: fixed;
  top: 48px;
  right: 36px;
}

.saved-items-quantity {
  position: fixed;
  top: 51px;
  right: 51px;
  color: #ffffff;
}

.saved-items-quantity > span {
  font-size: 20px;
  font-weight: 900;
}

.saved-items-button:hover .saved-items-quantity {
  transform: scale(1.2);
}

.uppercase {
  text-transform: uppercase;
}

.logo-container {
  width: 100px;
}

@media screen and (max-width: 600px) {
  .hide-menu-item {
    display: none;
  }

  .menu-items {
    font-size: 12px;
  }

  .search {
    left: 36px;
  }

  .main-header .search > span {
    font-size: 1rem;
    vertical-align: middle;
  }
}

@media screen and (max-width: 428px) {
  .main-header {
    justify-content: space-between;
  }

  .main-header .search > span {
    font-size: 1.5rem;
    vertical-align: bottom;
  }

  .search {
    position: relative;
    left: unset;
  }
}
