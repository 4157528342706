@import url('https://fonts.googleapis.com/css2?family=Sriracha&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bellota+Text:wght@700&display=swap');
/* google icon */
.login-w-google-image {
  width: 20px;
}

.google-sign-up-text {
  font-weight: 600;
  font-size: 0.85rem;
  padding-left: 0.25rem;
}

/* Section: login */
#loginUSER {
  margin-top: 1.5em;
  /* margin-bottom: 5rem; */
}

#loginUSER .login-container {
  padding: 0rem 0.75rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* grid-gap: 10px; */
}

#loginUSER .login-container .left-container > * {
  padding: 0 0 1.25rem 0;
}

#loginUSER .login-container .left-container .google-login {
  padding-bottom: 0;
  /* display: block; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* border: .5px solid #42464d; */
  padding: 0.5rem 3rem;
  width: 80%;
  margin: 1.25rem 0;
  /* border-radius: 5px; */
}

#loginUSER .login-container .left-container .login-text {
  font-size: 2rem;
  line-height: 1.25;
}

#loginUSER .login-container .left-container .login-text .trial-period {
  font-family: 'Sriracha', cursive;
  color: #1a60d9;
}

/* left-container */
#loginUSER .login-container .left-container .login-form {
  width: 80%;
}

#loginUSER .login-container .left-container .login-form .form-group {
  padding: 0.5rem 0;
}

#loginUSER .login-container .left-container .login-form .form-group input[type='email'],
#loginUSER .login-container .left-container .login-form .form-group input[type='password'] {
  width: 100%;
  font-size: 1rem;
  padding: 0.5rem 0.3rem;
  outline: none;
  border: 1px solid #ebebeb;
  border-radius: 5px;
}

#loginUSER .login-container .left-container .login-form .login-button {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  font-size: 1rem;
  margin: 0.75rem 0;
  background: #000;
  color: #fff;
  padding: 0.5rem 0.7rem;
  border: 1px solid #000;
  border-radius: 5px;
  transition: 0.2s all;

  display: flex;
  align-items: center;
  justify-content: center;
}

#loginUSER .login-container .left-container .login-form .login-button span {
  padding-right: 0.25rem;
}

#loginUSER .login-container .left-container .login-form .login-button:hover {
  background: #fff;
  color: #000;
}

#loginUSER .login-container .left-container .login-form .register-text {
  font-size: 0.75rem;
  margin-top: 1.25rem;
  color: #333;
  text-align: center;
}

#loginUSER .login-container .left-container .login-form .register-text .register-link {
  font-weight: bold;
}

#loginUSER .login-container .left-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#loginUSER .login-container .left-container,
#loginUSER .login-container .right-container {
  background: #fff;
  padding: 1.25rem 0.75rem;
}

#loginUSER .login-container {
  height: 700px;
}

#loginUSER .login-container .right-container {
  background: url('../images/login_image.jpg') no-repeat center center/cover;
}

#loginUSER .login-container .right-container {
  z-index: -1;
  display: flex;
  align-items: end;
}

#loginUSER .login-container .right-container .story {
  z-index: 0;
  margin-bottom: 2rem;
  opacity: 0.9;
  padding: 1.25rem.75rem;
  border-radius: 5px;
  line-height: 1.7;
  font-weight: 700;
  font-size: 1.25rem;
  color: #fff;
  font-family: 'Bellota Text', cursive;

  /* css glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

#loginUSER .login-container .right-container .story .story-footer {
  padding-top: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#loginUSER .login-container .right-container .story .story-footer .story-author .author-name {
  font-family: var(--site-font);
  font-weight: bold;
}

/* for smaller devices */
@media screen and (max-width: 900px) {
  #loginUSER .login-container .left-container .login-text {
    font-size: 1.5rem;
  }

  #loginUSER .login-container .right-container .story {
    font-size: 1rem;
  }
}

@media screen and (max-width: 700px) {
  #loginUSER .login-container .right-container .story .story-footer {
    flex-direction: column;
    align-items: flex-start;
  }

  #loginUSER .login-container .left-container .login-text {
    font-size: 1.25rem;
  }

  #loginUSER .login-container .right-container .story {
    font-size: 0.9rem;
  }

  .login-w-google-image {
    width: 15px;
    height: 15px;
  }

  .google-sign-up-text {
    font-size: 0.6rem;
  }
}

@media screen and (max-width: 600px) {
  .google-sign-up-text {
    font-size: 0.65rem;
  }

  #loginUSER .login-container {
    grid-template-columns: 1fr;
  }

  #loginUSER .login-container .right-container {
    display: none;
  }

  #loginUSER .login-container {
    height: 550px;
  }
}
