@keyframes fadeIn {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

#random-section {
  padding: var(--container-padding);
  margin-top: 1.25rem;
}

#random-section .random-section-container .random-items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

#random-section .random-section-container .random-items .item {
  background: #fff;
  padding: 1.25rem;
}

#random-section .random-section-container .random-items .item > p {
  text-align: center;
}

#random-section .random-section-container .random-items .item:hover {
  background: rgba(228, 221, 221, 0.1);
}

#random-section .random-section-container .random-items .item .buttons-container {
  display: flex;
  justify-content: end;
  gap: 10px;
  margin-top: 1.5rem;
}

#random-section .random-section-container .random-items .item .buttons-container > * {
  text-decoration: underline;
  font-size: 14px;
}

@media screen and (max-width: 715px) {
  #random-section .random-section-container .random-items .item > p:last-child {
    font-size: 14px;
  }
}

.hide-bigger-screen,
.hide {
  display: none;
}

.show {
  display: initial;
}

.gallery-item.show {
  animation: fadeIn 250ms ease-in-out;
}

.main-header .profile-img img {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center right;
}

.search-bar {
  display: flex;
  width: 55%;
}

.search-term {
  width: 100%;
  border: none;
  border-right: none;
  padding: 1.5em;
  height: 40px;
  border-radius: 5px;
  outline: none;
  color: #9dbfaf;
}

.search-term::placeholder {
  font-family: var(--site-font);
  font-weight: 500;
  color: #969696;
  opacity: 1;
}

.lnr-magnifier-bar {
  margin-left: -4em;
  color: #969696;
}

.slick-prev::before {
  content: '';
  font-family: 'FontAwesome';
  font-size: 22px;
  color: #ff7e00;
}

.slick-next::before {
  content: '';
  font-family: 'FontAwesome';
  font-size: 22px;
  color: #ff7e00;
}

.scrollable-categories {
  position: relative;
  width: 76%;
  text-align: center;
  background: #f9f9f9;
  padding: 0 0 10px 0;
}

.scrollable-categories .slick-slider .slick-list .slick-track {
  display: flex;
  justify-content: space-between;
}

.scrollable-categories .slick-slider .slick-list .slick-track .slick-slide {
  max-width: 150px;
  margin: 0 30px;
}

.slick-filter-scroll {
  background: #f9f9f9;
  position: sticky;
  top: 78px;
  animation: 0.2s fadeIn forwards ease-in;
}

/* .scrollable-categories::after {
  content: '';
  background: rgb(249, 249, 249);
  background: linear-gradient(
    90deg,
    rgba(249, 249, 249, 0) 0%,
    rgba(249, 249, 249, 1) 50%,
    rgba(249, 249, 249, 1) 100%
  );
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 100%;
  z-index: 2;
} */

.scrollable-categories .category {
  padding: 1em 0;
}

.scrollable-categories .category .filter-item {
  padding: 0.5em 1em;
  font-size: 1rem;
  font-family: var(--site-font);
  font-weight: 600;
  background-color: #fff;
  color: #646464;
  border-radius: 7px;
  border: thin solid #f7f5f5;
  transition: 250ms ease;
  white-space: nowrap;
  cursor: pointer;
}

.scrollable-categories .category .active,
.scrollable-categories .category .filter-item:hover {
  background-color: #646464;
  color: #fff;
  border-color: #646464;
}

.styles-container {
  margin: auto;
  max-width: 1250px;
}

.styles-container .styles-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.styles-container .styles-wrapper .style,
.styles-container .styles-wrapper .style img {
  height: 293px;
  width: 293px;
  object-fit: cover;
  object-position: center center;
}

.more-styles {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.more-styles .lnr-plus-circle {
  font-size: 4rem;
  color: #646464;
}

@media screen and (max-width: 950px) {
  .styles-container .styles-wrapper .style,
  .styles-container .styles-wrapper .style img {
    height: 250px;
    width: 250px;
  }

  .styles-container .styles-wrapper .style {
    margin: 6px;
  }
}

@media screen and (max-width: 800px) {
  .styles-container .styles-wrapper .style,
  .styles-container .styles-wrapper .style img {
    height: 200px;
    width: 200px;
  }
}

@media screen and (max-width: 600px) {
  #random-section .random-section-container .random-items {
    grid-template-columns: 1fr;
  }

  #random-section .random-section-container .random-items .item .buttons-container {
    justify-content: center;
  }

  /* hide menu items */
  .hide-menu-item {
    display: none;
  }

  .hide-bigger-screen {
    display: initial;
  }

  #hero .hero-container .header .main-header {
    flex-direction: row-reverse;
    padding: 1em;
  }

  #hero {
    padding: 0;
  }

  .scrollable-categories .category .filter-item {
    padding: 0.5em 1em;
    font-size: 0.75rem;
  }

  .styles-container .styles-wrapper .style,
  .styles-container .styles-wrapper .style img {
    height: 120px;
    width: 120px;
    object-fit: cover;
    object-position: center right;
  }

  .styles-container .styles-wrapper .style {
    margin: 2px;
  }

  #hero .hero-container {
    padding: 0;
  }

  .scrollable-categories {
    overflow: hidden;
  }
}

@media screen and (max-width: 428px) {
  .scrollable-categories {
    width: 95%;
  }

  .scrollable-categories .slick-slider .slick-list .slick-track .slick-slide {
    margin: 0 20px;
  }
}
