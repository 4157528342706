footer {
  margin: 2.25rem 0 0 0;
  background: #000;
  color: #fff;
  padding: 2.25rem;
  display: flex;
  flex-direction: column;
  gap: 26px;
}

.footer-links {
  display: flex;
  justify-content: space-around;
  gap: 20px;
}

.footer-links > a {
  color: #ffffff;
}

.subscribe-form {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.subscibe-input::placeholder {
  padding: 0 5px;
  text-align: center;
}

.toast-text {
  color: #000;
}

.close-notice {
  color: #000;
}

.copyright {
  display: flex;
  justify-content: center;
  padding-top: 0.5rem;
  border-top: 1px solid #b9b9b9;
}

@media screen and (max-width: 428px) {
  .footer-links {
    flex-direction: column;
    align-items: center;
  }

  .subscribe-form {
    flex-direction: column;
    gap: 2px;
  }
}
