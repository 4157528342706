.filters-sec {
  width: 100%;
  background: #ffffff;
}

.filters-wrapper {
  margin: auto;
  max-width: 1250px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.25rem;
}

.filters {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.filters-reverse {
  flex-direction: row-reverse;
}

.filter-icon {
  cursor: pointer;
  padding-top: 6px;
}

.dropdowns {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.hide-dropdowns {
  display: none;
}

.filters-section {
  width: 25%;
  max-width: 400px;
  padding-right: 1.5rem;
}

.dropdown {
  display: flex;
  gap: 20px;
  align-items: center;
}

.show-filter {
  display: block;
}

.hide-filter {
  display: none;
}

#filter-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 1000000;
  overflow-y: scroll;
}

#filter-modal .filter-modal-container {
  position: relative;
  padding: 1.25rem 0.75rem;
}

#filter-modal .filter-modal-container .close-button {
  position: absolute;
  right: 30px;
  top: 30px;
  border-radius: 50%;
  background-color: #000;
  color: #fff;
  padding: 0.75rem 1.25rem;
  font-size: 1.25rem;
  cursor: pointer;
}

#filter-modal .filter-modal-container .sticky .colors-section .colors-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  gap: 15px;
}

#search-results .search-results-container .filter-options {
  margin-top: 1.75rem;
}

#search-results .search-results-container .filter-options,
#search-results .search-results-container .filter-options .filters {
  display: flex;
  justify-content: space-between;
}

#search-results .search-results-container .filter-options .filters {
  flex-direction: column;
}

#search-results .search-results-container .filter-options .filters .filter-menu {
  display: none;
  align-items: center;
  margin: 1rem 0 0.5rem 0;
}

#search-results .search-results-container .filter-options .filters .filter-menu .filter-menu-box {
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #9a9494;
  padding: 0.25rem 0.7rem;
  display: flex;
  align-items: center;
  gap: 6px;
}

/* #search-results .search-results-container .filter-options .filters .category-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
} */

#search-results .search-results-container .filter-options .filters .options > * {
  margin-right: 0.75rem;
  font-size: 1.1rem;
  padding: 0.25rem 0.7rem;
  border: none;
  /* border-bottom: 1px solid #999; */
  cursor: pointer;
  outline: none;
  /* color: rgb(126, 165, 222); */
  background: #dad1c2;
}

/* #search-results .search-results-container .filter-options .filters .category-icons > * {
    margin: 0 .75rem;
    cursor: pointer;
}

#search-results .search-results-container .filter-options .filters .category-icons .img-container {
    width: 20px;
    height: 20px;
} */

/* search-results-line */
.search-results-line {
  width: 90%;
  height: 2px;
  background: #cecdcd;
  margin: 1.25rem auto;
}

/* search results products */
.filter-container {
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  /* right: 0; */
  /* max-width: 250px; */
  /* margin-left: 50px; */
  /* min-height:  100vh; */

  /* restricts to only this much height */
  /* max-height: 100vh; */

  /* goes on full  height as the  right side content goes */
  max-height: 100%;

  /* background: transparent; */
  /* padding-left: 80px; */

  overflow-y: scroll;
  /* text-align: center; */
  /* max-height: 700px; */
}

.filter-container .sticky {
  /* position: sticky; */
  /* top: 100px; */
  /* background: #ebebeb; */
  /* height: 100%; */
  /* max-height: 100%; */
  /* overflow-y: scroll; */
}

.filter-container .sticky .gender-section {
  /* margin-top: -1rem; */
}

.filter-title {
  padding: 1.5rem 0 0.5rem 0;
  padding-bottom: 1.5rem;
}

.space-1 {
  padding: 0.75rem 0;
}

.filter-line {
  height: 1.5px;
  width: 95%;
  background: rgb(229, 229, 229);
  margin: 1.25rem 0 0.75rem 0;
}

/* circles */
.colors-section .colors-list {
  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  justify-items: start; */
  display: flex;
  flex-wrap: wrap;
  /* width: 82%; */
  gap: 50px;
  padding-left: 10px;
  min-width: 240px;
  padding-bottom: 20px;
}

.color {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.color:hover {
  color: rgba(152, 146, 146, 0.839);
}

.color > div:first-child {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  padding-bottom: 0.5rem;
}

.color > span {
  font-size: 12px;
  text-align: center;
}

.color-label {
  position: absolute;
  top: 32px;
  white-space: nowrap;
}

.multi-color-circle + span {
  margin-left: -0.75rem;
}

.black-color-circle {
  background: black;
}
.blue-color-circle {
  background: rgb(23, 144, 200);
}
.brown-color-circle {
  background: rgb(130, 93, 65);
}
.green-color-circle {
  background: rgb(123, 186, 60);
}
.grey-color-circle {
  background: rgb(128, 128, 128);
}
.multi-color-circle {
  background: radial-gradient(rgb(255, 255, 255) 20%, transparent 20%) 0px 0px / 15px 15px,
    radial-gradient(rgb(255, 255, 255) 20%, transparent 20%) 8px 8px, rgb(0, 0, 0);
}
.orange-color-circle {
  background: rgb(243, 107, 38);
}
.pink-color-circle {
  background: rgb(240, 114, 143);
}
.purple-color-circle {
  background: rgb(141, 66, 159);
}
.red-color-circle {
  background: rgb(231, 53, 43);
}
.white-color-circle {
  background: rgb(255, 255, 255);
  border: 1px solid #1c1b1b21;
}
.yellow-color-circle {
  background: rgb(254, 213, 51);
}

/* for checkbox styligs */
/* The container */
.check-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 17px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.check-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.check-container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* modal-filter */
/* #filter-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 1000000;
  overflow-y: scroll;
}

#filter-modal .filter-modal-container {
  position: relative;
  padding: 1.25rem 0.75rem;
}

#filter-modal .filter-modal-container .close-button {
  position: absolute;
  right: 30px;
  top: 30px;
  border-radius: 50%;
  background-color: #000;
  color: #fff;
  padding: 0.75rem 1.25rem;
  font-size: 1.25rem;
  cursor: pointer;
}

#filter-modal .filter-modal-container .sticky .colors-section .colors-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  gap: 15px;
} */

@media screen and (max-width: 768px) {
  .filter-container {
    display: none;
  }

  .filter-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 1.25rem;
  }
}

@media screen and (max-width: 650px) {
  #search-results .search-results-container .filter-options,
  #search-results .search-results-container .filter-options .filters {
    flex-direction: column;
  }

  /* #search-results .search-results-container .filter-options .filters .category-icons {
        justify-content: left;
        margin: 1.75rem 0;
        padding-left: 0;
    } */
}
