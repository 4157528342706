.product-preview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.product-preview-tab-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  flex-direction: row;
  width: 300px;
  gap: 30px;
}

.product-image > img {
  height: auto;
  max-height: 80px;
  width: auto;
  object-fit: fill;
}

.product-name span {
  font-weight: bold;
  font-size: 0.75rem;
  color: #848282;
}
