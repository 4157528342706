@keyframes anim-forward {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

#heroHome {
  overflow: hidden;
  position: relative;
  z-index: 1;
  -webkit-box-pack: center;
  margin-top: -80px;
  min-height: 500px;
}

.image-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  max-height: 90vh;
}

.image-container-reverse {
  flex-direction: row-reverse;
}

.video-container {
  max-height: 90vh;
}

.media-text-container {
  position: absolute;
  top: 300px;
  background: transparent;
  min-height: 500px;
}

.text-container-split {
  width: 50%;
  position: relative;
  top: 0;
  background: #afaeb3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  display: none;
  font-size: 2rem;
  color: #fff;
}

.text-visible {
  display: visible;
  font-size: 2rem;
  color: #fff;
}

.rockin {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 6.5rem;
  animation: 3.5s anim-forward forwards ease-in;
  z-index: 300000;
}

.rockin > span {
  font-family: var(--lobster);
  font-size: 2rem;
  color: #ffffff;
}

.sounds-icon:hover {
  transform: scale(1.15);
  cursor: pointer;
}

.sounds-on {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}

.sounds-off {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}

.invisible {
  display: none;
}

@media screen and (max-width: 768px) {
  .text {
    font-size: 1.75rem;
    line-height: 1.5;
  }
}

@media screen and (max-width: 428px) {
  #heroHome {
    min-height: 0;
  }

  .rockin > span {
    font-size: 1.5rem;
  }

  video {
    max-height: 30vh;
  }

  .text {
    font-size: 1.5rem;
  }

  .sounds-on {
    bottom: 1rem;
  }

  .sounds-off {
    bottom: 1rem;
  }

  .sounds-icon > svg {
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-height: 450px) {
  .text {
    font-size: 1rem;
    line-height: 1.5;
    margin-top: -4.5rem;
  }
}
