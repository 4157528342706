#support {
  padding: 0 5rem;
  margin-top: 3.25rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.support-title {
  font-weight: bold;
}

@media screen and (max-width: 428px) {
  #support {
    padding: 0 3rem;
    gap: 1rem;
  }
}
