.search-section {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background: rgba(0, 0, 0, 0.8);
  height: 100vh;
}

.search-container {
  background: #ffffff;
  z-index: 4;
  overflow-y: scroll;
  overflow-x: hidden;
}

.search-form-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  position: sticky;
  background: #ffffff;
  width: 100%;
  z-index: 4;
  top: 0;
  left: 0;
  border-bottom: 2px solid rgb(234, 234, 234);
}

.filter-and-close-container {
  display: flex;
  flex-direction: row;
}

.close-modal {
  display: flex;
  align-items: center;
  padding: 1.25rem 1.25rem 1.5rem;
}

.close-modal > .lnr-cross {
  font-size: 32px;
  cursor: pointer;
}

.modal-search-input {
  width: 220px;
  padding: 0 1rem;
  border: 2px solid rgb(89, 89, 89);
  border-radius: 32px;
  outline: none;
  font-size: 1.2rem;
  color: rgb(89, 89, 89);
}

.modal-search-input::placeholder {
  color: rgb(110, 110, 110);
}

.modal-search-input:focus {
  border: 2px solid rgb(89, 89, 89);
  outline: none;
}

.modal-search-input::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.more-products {
  position: absolute;
  left: 300px;
  cursor: pointer;
}

.preview-title {
  padding: 1rem 2rem 0;
}

.propuct-preview-container {
  display: flex;
}

.propuct-preview {
  width: 72%;
}

.propuct-preview-freestyle {
  width: 100%;
}

.product-preview-title {
  padding: 2rem;
}

.propuct-preview-results {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 0 2rem 2rem;
  gap: 30px;
}

span {
  font-size: 18px;
  font-weight: 900;
}

.link {
  cursor: pointer;
}

.categories-container {
  width: 28%;
  display: flex;
  gap: 20px;
  padding: 2rem;
  border-left: 2px solid rgb(234, 234, 234);
}

.category {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.sneaker-brands {
  white-space: nowrap;
  width: fit-content;
}

.apparel {
  white-space: nowrap;
  width: fit-content;
}

.categories-title {
  padding-bottom: 1.5rem;
}

/* .modal-search-button {
  width: 150px;
  border: 1px solid orange;
  border-radius: 8px;
  background: orange;
} */

.modal-search-icon {
  position: absolute;
  right: 6rem;
  cursor: pointer;
  border-radius: 16px;
  background: #ff7e00;
  color: #ffffff;
  padding: 5px 15px 5px 5px;
}

@media screen and (max-width: 428px) {
  .search-container {
    height: 100vh;
  }

  .more-products {
    top: 96px;
    left: unset;
    right: 30px;
  }

  .modal-search-icon {
    top: 4rem;
    right: -9.5rem;
    z-index: 1;
    font-size: 1rem;
    padding: 0px 10px 0px 0px;
  }

  .modal-search-icon > span {
    font-size: 1rem;
  }

  .close-modal {
    padding: 0 0 8px 0;
  }
}
