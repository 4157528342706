.custom-dropdown {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  position: relative;
}

.label {
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c8c8c8;
  border-radius: 4px;
  padding: 0 1rem;
}

.label:hover {
  cursor: pointer;
}

.dropdown-children {
  min-width: 120px;
  position: absolute;
  top: 32px;
  background: #ffffff;
  border: 1px solid #c8c8c8;
  padding: 1rem;
  z-index: 1;
}

.last {
  right: 0;
}
