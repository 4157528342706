#enlarge-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.8);
  padding: 1.25rem 0.75rem;
  height: 100vh;
}

.hidden-enlarge {
  display: none !important;
}

#enlarge-modal .enlarged-modal-container {
  position: relative;
  height: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

#enlarge-modal .enlarged-modal-container .close-modal {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 32px;
  cursor: pointer;
  padding: 0;
}

.image-wrapper {
  display: contents;
}

.image-style-wrapper {
  display: flex;
  justify-content: center;
}

.image-container {
  display: flex;
  justify-content: center;
}

.image-div {
  position: relative;
  background: #ffffff;
}

.enlarged-view-icons {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 20px;
  cursor: pointer;
}

.related-products-container {
  width: 25%;
  background: #ffffff;
  padding: 2rem;
  overflow-y: auto;
  max-height: 90vh;
}

.related-products-title-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
}

.related-products-title {
  font-size: 0.8rem;
  line-height: 1.25;
  color: #000000;
}

.related-products-line {
  width: 97%;
  height: 1px;
  background: rgb(208, 208, 208);
}

.image {
  max-width: 700px;
  max-height: 90vh;
}

.image-style {
  max-width: unset;
  max-height: 90vh;
}

@media screen and (max-width: 700px) {
  .image {
    max-width: 90%;
  }

  .image-container {
    display: block;
  }

  .image-style-container {
    position: absolute;
    top: 50px;
  }
  .image-style-wrapper {
    flex-direction: column;
  }

  .image-div {
    text-align: center;
  }

  .image-style {
    max-height: 100%;
    height: 100%;
  }

  .image-wrapper {
    display: flex;
    flex-direction: column;
  }

  .related-products-container {
    width: 95vw;
    align-self: center;
  }
}

@media screen and (max-width: 428px) {
  .image {
    max-width: 90%;
  }

  #enlarge-modal {
    z-index: 4;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0;
  }

  .enlarged-modal-container {
    align-items: center;
  }

  .related-products-container {
    overflow-y: unset;
    max-height: unset;
    width: 100vw;
  }

  .image-style-container {
    top: 0;
  }

  .close-modal {
    z-index: 1;
  }
}
