.splide__track {
  margin: 0 70px;
}

.splide__arrows {
  position: inherit;
  top: 36px;
}

.splide__arrow {
  opacity: 0.3 !important;
}

.pag-button {
  background: #c7c5c5 !important;
}

li > .is-active {
  background: #a2a1a1 !important;
}

@media screen and (max-width: 428px) {
  .splide__arrows {
    display: none;
  }

  .splide__pagination {
    bottom: 0.1em !important;
  }
}
