@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
/* contact-us */
#contact-us {
  margin-top: 1.5em;
}

#contact-us .contact-us-container {
  display: grid;
  grid-template-columns: 4fr 2fr;
  grid-gap: 25px;
  padding: 0 0.75rem;
  max-height: 80vh;
}

#contact-us .contact-us-container .img-container {
  background: url('../images/contact_us.jpg') no-repeat top center/cover;
}

#contact-us .contact-us-container .text-container {
  display: flex;
  flex-direction: column;
}

#contact-us .contact-us-container .text-container .header-text {
  padding-bottom: 1rem;
}

#contact-us .contact-us-container .text-container .description-text {
  line-height: 1.7;
}

#contact-us .contact-us-container .text-container .contact-us-form-container .names-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

#contact-us .contact-us-container .text-container .contact-us-form-container .names-container .form-group label,
#contact-us .contact-us-container .text-container .contact-us-form-container .form-group label {
  padding-bottom: 0.5rem;
}

#contact-us .contact-us-container .text-container .contact-us-form-container .names-container .form-group,
#contact-us .contact-us-container .text-container .contact-us-form-container .form-group {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
}

#contact-us .contact-us-container .text-container .contact-us-form-container .form-group:last-child {
  padding: 1rem 0 0 0;
}

#contact-us .contact-us-container .text-container .contact-us-form-container .form-group textarea {
  resize: none;
  height: 200px;
  padding: 0.4rem 0.5rem;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1rem;
  border: none;
  background: #ebebeb;
  outline: none;
  border-radius: 2px;
}

#contact-us
  .contact-us-container
  .text-container
  .contact-us-form-container
  .names-container
  .form-group
  input[type='text'],
#contact-us .contact-us-container .text-container .contact-us-form-container .form-group input[type='email'] {
  width: 100%;
  font-size: 1rem;
  font-family: 'Source Sans Pro', sans-serif;
  padding: 0.4rem 0.5rem;
  border: none;
  background: #ebebeb;
  outline: none;
  border-radius: 2px;
}

#contact-us
  .contact-us-container
  .text-container
  .contact-us-form-container
  .form-group:last-child
  input[type='submit'] {
  background: #e53967;
  color: #fff;
  padding: 0.75rem 1.75rem;
  font-size: 1.07rem;
  cursor: pointer;
  transition: 0.2s all;
}

#contact-us
  .contact-us-container
  .text-container
  .contact-us-form-container
  .form-group:last-child
  input[type='submit']:hover {
  background: #ff3b6e;
}

/* for smaller devices */
@media screen and (max-width: 1000px) {
  #contact-us .contact-us-container {
    grid-template-columns: 2fr 2fr;
  }

  #contact-us .contact-us-container .text-container {
    padding: 1.25rem 0;
  }
}

@media screen and (max-width: 650px) {
  #contact-us .contact-us-container {
    grid-template-columns: 1fr;
  }

  #contact-us .contact-us-container .img-container {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  #contact-us .contact-us-container {
    max-height: unset;
  }

  #contact-us .contact-us-container .text-container .contact-us-form-container .names-container {
    grid-template-columns: 1fr;
  }

  #contact-us .contact-us-container .text-container .contact-us-form-container .form-group,
  #contact-us .contact-us-container .text-container .contact-us-form-container .names-container .form-group {
    padding: 0.7rem 0;
  }
}
