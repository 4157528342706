/* imports */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fauna+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

/* Root scope */
:root {
  --site-font: 'Open Sans', sans-serif;
  --container-padding: 0 1.25rem;
  --kaushan-script-font: 'Kaushan Script', cursive;
  --lobster: 'Lobster', cursive;
}

/* for home page slider button */
.read-more-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 2rem;
  padding: 1.75rem 1.75rem 0 1.75rem;
}

.read-more-btns {
  white-space: nowrap;
  font-size: 12px;
  font-weight: 900;
  cursor: pointer;
  color: #ff7e00;
}

.read-more-btns:hover {
  text-decoration: underline;
}

/* relative/absolute custom */
.relative-custom {
  position: relative;
}

.absolute-custom {
  position: absolute;
}

/* for facts inside of a shoe slider */
.slider-facts {
  display: flex;
  gap: 10px;
  font-size: 14px;
  bottom: 50px;
  right: -40px;
  bottom: 0px;
  padding: 0 1.25rem;
  border-bottom: 1px solid tan;
  color: tan;

  /* border: 2px solid tan; */
}

/* .slider-facts::after {
  content: '→';
  font-size: 14px;
  color: tan;
} */

/* inline-group */
/* .inline-btn {
    position: absolute;
    right: -12px;
    bottom: -10px;
    background: #53359d;
    font-size: 10px;
    padding: .25rem .5rem;
    margin: .75rem;
    color: #fff;
    border-radius: 50%;
    font-weight: 900;
} */

/* Resets */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Core  stylings */
a {
  text-decoration: none;
  color: #000;
}

img {
  width: 100%;
  height: 100%;
}

ul {
  list-style: none;
}

button,
input[type='submit'] {
  background: none;
  border: none;
}

.container {
  max-width: 1250px;
  margin: auto;
}

body {
  font-family: var(--site-font);
  background: rgb(249, 249, 249);
  overflow: scroll;
}

/* Padding classes */
.py-1 {
  padding: 1rem 0;
}

.py-2 {
  padding: 2rem 0;
}

.py-3 {
  padding: 3rem 0;
}

.py-4 {
  padding: 4rem 0;
}

.px-1 {
  padding: 0 1rem;
}

.px-2 {
  padding: 0 2rem;
}

.px-3 {
  padding: 0 3rem;
}

.px-4 {
  padding: 0 4rem;
}

/* Margin classes */
.my-1 {
  margin: 1rem 0;
}

.my-2 {
  margin: 2rem 0;
}

.my-3 {
  margin: 3rem 0;
}

.my-4 {
  margin: 4rem 0;
}

.mx-1 {
  margin: 0 1rem;
}

.mx-2 {
  margin: 0 2rem;
}

.mx-3 {
  margin: 0 3rem;
}

.mx-4 {
  margin: 0 4rem;
}

/* font classes */
.white-text {
  color: #fff;
}

.black-text {
  color: #000;
}

.grey-text {
  color: #999;
}

.font-1 {
  font-size: 1rem;
}
.font-2 {
  font-size: 2rem;
}
.font-3 {
  font-size: 3rem;
}
.font-4 {
  font-size: 3rem;
}

/* cases, underline */
.uppercase {
  text-transform: uppercase;
  /* margin-bottom: 1.25rem; */
}

.underline {
  text-decoration: underline;
}

/* show and hide */
.show {
  display: block;
}
.hide {
  display: none;
}

/* round-currency */
.round-currency {
  border-radius: 20px;
  padding: 0.25rem 0.75rem;
  border: 0.3px solid #999;
}

/* button classes */
.classic-black-btn {
  background: #000;
  padding: 0.5rem 1.25rem;
  text-align: center;
  color: #fff;
  transition: 0.3s letter-spacing, 0.2s background-color;
}

.classic-black-btn:hover {
  letter-spacing: 0.25rem;
  background-color: rgb(48, 47, 47);
}

/* slider */
/* .slick-frame {
    visibility: hidden;
}

.slick-frame.slick-initialized {
    visibility: visible;
} */

.red-sidebar-click {
  background: rgb(228, 69, 69);
  color: #fff;
  padding: 0.55rem 0.5rem;
  transform: rotate(-90deg);
  position: fixed;
  left: -45px;
  /* top: 28%; */
  z-index: 1000;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 0.75rem;
}

/* positioning from the top */
.top-28 {
  top: 28%;
  left: -55px;
  padding: 0.25rem 1.75rem !important;
}

.top-70 {
  top: 70%;
  left: -55px;
  padding: 0.25rem 1.75rem !important;
}

.top-90 {
  top: 90%;
  left: -50.5px;
  padding: 0.25rem 1.75rem !important;
  /* font-size: .9rem; */
}

.top-25 {
  top: 25%;
  left: -55px;
  padding: 0.25rem 1.75rem !important;
}

.top-50 {
  top: 50%;
  left: -57px;
  padding: 0.25rem 1.75rem !important;
}

.top-75 {
  top: 75%;
  left: -46px;
  padding: 0.25rem 1.25rem !important;
  /* font-size: .9rem; */
}

.red-sidebar-click:hover {
  background: rgb(247, 68, 68);
}

/* card */
.card {
  display: flex;
  margin-right: 0;
  padding: 1.25rem;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  transition: 0.2s color, 0.2s transform, 0.1s background;
  background: #fff;
}

.card-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card .card-text span {
  font-weight: bold;
  font-size: 0.75rem;
  color: #848282;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.card .card-text .product-title {
  color: #09aeda;
}

.card .card-text .product-title:hover {
  font-size: 0.8rem;
}

.card .card-text b {
  font-size: 0.8rem;
  line-height: 1.25;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.img-container {
  cursor: pointer;
}

.img-container:hover img {
  /* background: rgb(218, 73, 83); */
  transform: scale(1.04);
}

/* mobile-tab */
.mobile-tab {
  background: #999;
  padding: 0.3rem 0.75rem;
  color: #fff;
  font-weight: bold;
  transition: 0.3s letter-spacing, 0.3s background-color;
}

.mobile-tab:hover {
  letter-spacing: 0.5px;
  background: #111;
}

/* filter select option */
.filter {
  display: flex;
  justify-content: flex-end;
}

.filter select {
  padding: 0.2rem 0.3rem;
  cursor: pointer;
  outline: none;
  border: none;
  border-bottom: 1px solid #848282;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
}

/* tabs : hide at first */
#tabs {
  /* display: none; */
}

/* nav: navbar [hamburger] */
#navbar {
  display: none;
  z-index: 111111111111111111111111111111111;
  /* position: relative; */
}

#navbar.showNavBar {
  display: block;
  z-index: 11;

  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;

  /* flex */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;

  padding: 2.25rem 0.5rem;
  overflow-y: scroll;

  background: rgba(0, 0, 0, 0.9);
}

#navbar.showNavBar a {
  color: #fff;
  padding: 1.25rem 0;
  font-weight: bold;
  font-size: 1.5rem;
  transition: 0.3s color, 0.3s letter-spacing;
}

#navbar.showNavBar a:hover {
  color: #999;
  letter-spacing: 0.25rem;
}

#navbar.showNavBar .lnr-cross {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  position: fixed;
  right: 70px;
  top: 20px;
  transition: 0.3s color;
  cursor: pointer;
}

#navbar.showNavBar .lnr-cross:hover {
  color: red;
}

/* navbar: end [hamburger] */

/* start of search-modal */
.search-modal {
  display: none;
  display: block !important;

  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 1000;
}

.show {
  display: block !important;
}

.search-modal-container {
  /* position: relative; */
  padding: 1.5rem 0 0 3.25rem;
  height: 100%;
}

.search-modal-container .top-right-escape {
  cursor: pointer;
  position: absolute;
  right: 42px;
  font-size: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-modal-container .top-right-escape > :last-child {
  /*font-size: .75rem;*/
}

.search-modal-container form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.search-input {
  display: block;
  font-family: var(--site-font);
  /* padding: 0.25rem; */
  /* border: none; */
  border: 2px solid #ffffff;
  border-radius: 32px;
  background: transparent;
  font-size: 1.2rem;
  outline: none;
  width: 220px;
  margin: auto;
  color: #ffffff;
  padding: 0 1rem;
}

.search-input::placeholder {
  color: #ffffff;
  /* padding: 0 2rem; */
}

.search-input-v2 {
  border: 2px solid rgb(89, 89, 89);
  color: rgb(89, 89, 89);
}

.search-input-v2::placeholder {
  color: rgb(89, 89, 89);
  /* padding: 0 2rem; */
}

.search-modal-container form input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  /* height: 14px;
  width: 14px;
  display: block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-size: 14px;
  cursor: pointer; */
}

.search-modal-container form input[type='submit'] {
  font-family: var(--site-font);
  margin-top: 1.25rem;
  /* cursor: pointer; */
  border: 1px solid #000;
  padding: 0.4rem 2rem;
  font-size: 1.1rem;
  transition: 0.2s all;
}

.search-modal-container form input[type='submit']:hover {
  background: #000;
  color: #ffffff;
}

/* end of search-modal */

/* Hiding all other tabs at the beginning */
/* #tab-1-contnet,
#tab-2-content,
#tab-3-content,
#tab-4-content,
#tab-5-content {
    display: none;
} */

.tab-content-item {
  display: none;
  /* margin-top: 1.25rem; */
}

.icon {
  width: 28px;
}

/* show */
.show-tab {
  display: block !important;
}

.show-tab {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* logo-container */
.logo-container {
  max-width: 100px;
}

/* .logo {
    color: rgb(230, 57, 57);
    font-weight: bold;
    font-size: 2.5rem;
    max-width: 350px;
} */

.navbar > div {
  width: 30px;
  height: 1px;
  background: #fff;
  margin: 0.5rem 0;
}

.navbar-v2 > div {
  width: 30px;
  height: 1px;
  margin: 0.5rem 0;
  background: rgb(53, 51, 51);
}

.hamburger-menu-line {
  width: 30px;
  height: 1px;
  background: white;
  margin: 0.5rem 0;
}

.hamburger-menu-line-v2 {
  background: rgb(53, 51, 51);
}

/* section: tabs */
#tabs {
  padding: 0.5rem 0;
  margin-bottom: 0.75rem;
  background: #fff;
}

.stick-tab {
  /* sticky */
  /* position: sticky; */
  top: 0;
  /* z-index: 0; */
}

#tabs .tabs-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

#tabs .tabs-container .tab {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 80px;
  width: 100%;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  color: #fff;
  cursor: pointer;
  border-bottom: 2px solid;
}

#tabs .tabs-container .tab:hover {
  border-bottom: 2px solid #ff7e00;
}

.active-tab {
  border-bottom: 2px solid red !important;
}

/* section: tab-contents */
#tab-contents {
  padding: var(--container-padding);
  padding-bottom: 1.25rem;
  max-width: 95%;
  margin: auto;
}

/* section: new-releases */
#new-releases {
  max-width: 1250px;
  margin: auto;
  margin-top: 2.25rem;
}

/* for shoes, pants container */
/* #tab-contents .tab-contents-container .tab-content-item .shoes,
#tab-contents .tab-contents-container .tab-content-item .pants,
#tab-contents .tab-contents-container .tab-content-item .tshirts,
#tab-contents .tab-contents-container .tab-content-item .jackets,
#tab-contents .tab-contents-container .tab-content-item .hats, */

.tab-contents-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

#top-20-picks .top-20-picks-container .picks,
#featured .featured-container .featured-items,
#new-releases .new-releases-container .releases {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  margin-top: 1.25rem;
}

.picks-virtual {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  margin-top: 1.25rem;
}

#tab-contents .tab-contents-container .tab-content-item .shoes .shoe,
#tab-contents .tab-contents-container .tab-content-item .pants .pant,
#tab-contents .tab-contents-container .tab-content-item .tshirts .tshirt,
#tab-contents .tab-contents-container .tab-content-item .jackets .jacket,
#tab-contents .tab-contents-container .tab-content-item .hats .hat,
#top-20-picks .top-20-picks-container .picks .pick,
#featured .featured-container .featured-items .item,
#shoes .shoes-container .shoes-list .shoe,
#new-releases .new-releases-container .releases .release {
  display: flex !important;
  text-align: right;
  gap: 20px;
  /* box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px; */
  line-height: 2;
  /* justify-content: space-between; */
}

#top-20-picks .top-20-picks-container .picks .pick,
#featured .featured-container .featured-items .item,
#shoes .shoes-container .shoes-list .shoe,
#new-releases .new-releases-container .releases .release {
  padding: 1.25rem 0.3rem;
  flex-direction: column;
}

#tab-contents .tab-contents-container .tab-content-item .shoes .shoe .img-container {
  max-height: 90px;
  max-width: 90px;
}

#tab-contents .tab-contents-container .tab-content-item .last-button,
#top-20-picks .top-20-picks-container .last-button,
#featured .featured-container .last-button,
#new-releases .new-releases-container .last-button {
  display: flex;
  justify-content: center;
  margin: 2.5rem 0;
}

/* for pants */
/* #tab-contents .tab-contents-container .tab-content-item .pants .pant {
    display: flex;
    flex-direction: row;
} */

#tab-contents .tab-contents-container .tab-content-item .pants .pant .img-container {
  /* height: 250px; */
  /* width: auto; */
  max-height: 90px;
  max-width: 90px;
  /* height: 88.76px; */
  /* width: 88.76px; */
}
#tab-contents .tab-contents-container .tab-content-item .pants .pant .img-container > img {
  max-height: 100%;
  max-width: 100%;
  /* height: 88.76px; */
  /* width: 88.76px; */
}

/* shoes */
#shoes {
  margin-top: 2.75rem;
}

.tab-card-image {
  height: 80px;
  width: 100%;
}

/* section: top-20-picks */
#top-20-picks,
#new-releases .new-releases-container {
  padding: var(--container-padding);
  margin-top: 2.25rem;
}

/* section: featured [air-jordan] */
#featured {
  padding: var(--container-padding);
}

/* section: shop-by-category */
#shop-by-category {
  padding: var(--container-padding);
  max-width: 100%;
  margin: auto;
}

#shop-by-category .shop-by-category-container .slider {
  margin: 1.25rem 0;
  /* max-width: 95%; */
}

#shop-by-category .shop-by-category-container .slider b {
  display: block;
  text-align: center;
  padding: 0.75rem 0;
  line-height: 1.75;
}

#shop-by-category .shop-by-category-container .slider .slider-item {
  /* background: teal; */
  background: #fff;
  margin: 0 0.25rem;
}

/* section: blog-stories */
#blog-stories {
  padding: var(--container-padding);
}

#blog-stories .blog-stories-container .stories {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

#blog-stories .blog-stories-container .stories .history,
#blog-stories .blog-stories-container .stories .blog {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

#blog-stories .blog-stories-container .stories .history .img-container img,
#blog-stories .blog-stories-container .stories .blog .img-container img {
  min-height: 400px;
  max-height: 400px;
}

#blog-stories .blog-stories-container h1 {
  margin: 2.75rem 0 0.75rem 0;
}

#blog-stories .blog-stories-container .stories .history .text-container p,
#blog-stories .blog-stories-container .stories .blog .text-container p,
#blog-stories .blog-stories-container .stories .history .text-container b,
#blog-stories .blog-stories-container .stories .blog .text-container b {
  line-height: 1.75;
}

#blog-stories .blog-stories-container .stories .history .text-container a:last-child,
#blog-stories .blog-stories-container .stories .blog .text-container a:last-child {
  display: inline-block;
  text-decoration: underline;
  font-weight: bold;
  margin-top: 1.25rem;
}

#blog-stories .blog-stories-container .stories .history .text-container b,
#blog-stories .blog-stories-container .stories .blog .text-container b {
  font-size: 1.25rem;
}

/* section: top-picks */
#top-picks {
  padding: var(--container-padding);
}

#top-picks .top-picks-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

#top-picks .top-picks-container .pick {
  position: relative;
}

#top-picks .top-picks-container .pick .text-container {
  position: absolute;
  top: 50%;
  left: 15%;
  padding: 1.25rem 2rem;
  background: #fff;
  color: #000;
  text-align: center;
  line-height: 2;
}

#top-picks .top-picks-container .pick .text-container .name {
  font-family: 'Fauna One', serif;
  font-size: 0.7rem;
}

#top-picks .top-picks-container .pick .text-container .job-role,
#top-picks .top-picks-container .pick .text-container .shop-now-link {
  font-family: var(--kaushan-script-font);
}

/* For smaller devices */
@media screen and (max-width: 1250px) {
  #top-picks .top-picks-container .pick .text-container {
    position: absolute;
    top: 30%;
    left: 20%;
    padding: 0.75rem 1rem;
  }
}

@media screen and (max-width: 1100px) {
  #top-picks .top-picks-container .pick .text-container {
    position: absolute;
    top: 30%;
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 1050px) {
  #blog-stories .blog-stories-container .stories {
    grid-template-columns: 1fr;
  }

  #blog-stories .blog-stories-container .stories .history,
  #blog-stories .blog-stories-container .stories .blog {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }

  #blog-stories .blog-stories-container .stories .history .text-container,
  #blog-stories .blog-stories-container .stories .blog .text-container {
    padding: 1.25rem 3rem;
  }
}

@media screen and (max-width: 1000px) {
  #top-picks .top-picks-container .pick .text-container {
    left: 18%;
  }
}

@media screen and (max-width: 850px) {
  #blog-stories .blog-stories-container .stories .history,
  #blog-stories .blog-stories-container .stories .blog {
    grid-template-columns: 1fr;
  }

  #blog-stories .blog-stories-container .stories .history .text-container,
  #blog-stories .blog-stories-container .stories .blog .text-container {
    padding: 1.25rem 0.5rem;
  }

  #top-picks .top-picks-container .pick .text-container {
    left: 12%;
  }
}

@media screen and (max-width: 800px) {
  #tab-contents .tab-contents-container .tab-content-item .shoes,
  #tab-contents .tab-contents-container .tab-content-item .pants,
  #tab-contents .tab-contents-container .tab-content-item .tshirts,
  #tab-contents .tab-contents-container .tab-content-item .jackets,
  #tab-contents .tab-contents-container .tab-content-item .hats,
  #top-20-picks .top-20-picks-container .picks,
  #featured .featured-container .featured-items,
  #new-releases .new-releases-container .releases {
    grid-template-columns: repeat(2, 1fr);
  }

  #tab-contents .tab-contents-container .tab-content-item .shoes .shoe,
  #tab-contents .tab-contents-container .tab-content-item .pants .pant,
  #tab-contents .tab-contents-container .tab-content-item .tshirts .tshirt,
  #tab-contents .tab-contents-container .tab-content-item .jackets .jacket,
  #tab-contents .tab-contents-container .tab-content-item .hats .hat,
  #top-20-picks .top-20-picks-container .picks .pick,
  #featured .featured-container .featured-items .item,
  #shoes .shoes-container .shoes-list .shoe,
  #new-releases .new-releases-container .releases .release {
    font-size: 0.75rem;
  }

  #blog-stories .blog-stories-container .stories .history .img-container img,
  #blog-stories .blog-stories-container .stories .blog .img-container img {
    width: 100%;
  }

  #blog-stories .blog-stories-container .stories .blog .text-container b,
  #blog-stories .blog-stories-container .stories .history .text-container b {
    font-size: 1.1rem;
  }

  #blog-stories .blog-stories-container .stories .blog .text-container p,
  #blog-stories .blog-stories-container .stories .history .text-container p {
    font-size: 0.75rem;
  }

  #blog-stories .blog-stories-container .stories .blog .text-container a,
  #blog-stories .blog-stories-container .stories .blog .terms-privacy a {
    font-size: 0.9rem;
  }

  .search-modal-container {
    width: 100vw;
    /* background-color: #999999; */
    background-color: #ffffff;

    z-index: 10;
    padding: 1.5rem 3.25rem;
  }

  .search-modal-container-v2 {
    background-color: #ffffff;
  }
}

@media screen and (max-width: 720px) {
  #top-picks .top-picks-container .pick .text-container {
    top: 50%;
    /* left: 20%; */
    font-size: 0.7rem;
  }

  #top-picks .top-picks-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  /* hide menu items */
  .hide-menu-item {
    display: none;
  }

  /* hamburger menu items */
  #navbar.show a {
    font-size: 1rem;
  }
}

@media screen and (max-width: 550px) {
  #top-picks .top-picks-container .pick .text-container {
    left: 25%;
  }
}

@media screen and (max-width: 500px) {
  #tabs .tabs-container {
    max-width: 15%;
  }

  .search-modal-container form input[type='search'] {
    width: 100%;
    font-size: 1.25rem;
  }

  .search-modal-container form input[type='submit'] {
    font-size: 0.85rem;
  }
}

@media screen and (max-width: 465px) {
  #top-picks .top-picks-container .pick .text-container {
    left: 11%;
    top: 60%;
  }
}

@media screen and (max-width: 450px) {
  /* for mobile-tab */
  .mobile-tab {
    display: inline-block;
    text-align: center;
  }

  #top-20-picks .top-20-picks-container .picks,
  #featured .featured-container .featured-items,
  #new-releases .new-releases-container .releases {
    /* grid-template-columns: 1fr; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* grid-template-columns: repeat(2, 1fr); */
  }

  #new-releases .new-releases-container .releases .release {
    padding: 1.25rem 0.5rem;
  }
  .card {
    width: 48%;
    justify-content: space-between;
  }

  .card .card-text {
    width: 100%;
    white-space: nowrap;
    align-items: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card .card-text span {
    font-size: 0.7rem;
  }

  .card .card-text b {
    font-size: 0.75rem;
  }

  .read-more-container {
    column-gap: 1.25rem;
  }

  .shoe {
    padding-left: 3rem;
  }

  .tab-card-image {
    height: auto;
    max-height: 80px;
    width: auto;
    object-fit: fill;
  }

  .search-input {
    border: 2px solid rgb(89, 89, 89);
    color: rgb(89, 89, 89);
  }

  .search-input::placeholder {
    color: rgb(89, 89, 89);
  }
}

@media screen and (max-width: 400px) {
  #top-picks .top-picks-container .pick .text-container {
    left: 9%;
    top: 50%;
  }
}

@media screen and (max-width: 370px) {
  #top-picks .top-picks-container {
    grid-template-columns: 1fr;
  }

  #top-picks .top-picks-container .pick .text-container {
    left: 30%;
    top: 75%;
  }

  .read-more-container {
    column-gap: 1rem;
  }
}
