@import url('https://fonts.googleapis.com/css2?family=Edu+VIC+WA+NT+Beginner&display=swap');

body { background: #fff; }
.blue-text { color: dodgerblue; }

.reset-btn {
    margin: .75rem 0;
    display: block;
    width: 100%;
    background-color: rgb(9,98,238);
    color: #fff;
    font-size: 1rem;
    padding: .5rem .75rem;
    border-radius: 5px;
    cursor: pointer;
}

.reset-btn:hover {
    background-color: rgb(0, 98, 255);
}

.login-link {
    color: rgb(9,98,238);
    font-weight: 500;
}

/* Section: reset-password */
#reset-password {
    margin-top: 7em;
    margin-left: .5rem;
    margin-right: .5rem;
}

#reset-password .reset-password-container {
    display: grid;
    grid-template-columns: 3fr 5fr;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

#reset-password .reset-password-container .left-img-container {
    position: relative;
    background: url('../images/bg_reset.webp') no-repeat center center/cover;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#reset-password .reset-password-container .left-img-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: -1;
}

#reset-password .reset-password-container .left-img-container .text-container {
    z-index: 2;
    color: #fff;
    padding: 1.25rem 1.75rem;
    line-height: 1.7;
    font-family: 'Edu VIC WA NT Beginner', cursive;
    font-size: 1.75rem;
}

#reset-password .reset-password-container .left-img-container .text-container .user-quote {
    font-size: 1.5rem;
    padding-bottom: .75rem;
}

#reset-password .reset-password-container .left-img-container .text-container .username {
    font-weight: bold;
}

#reset-password .reset-password-container .right-text-container {
    /* padding: 1.5rem .75rem 1.5rem 4rem; */
    padding: 4rem .75rem;
    margin: auto;
    max-width: 500px;
}

#reset-password .reset-password-container .right-text-container .top-title {
    margin-bottom: 2.5rem;
}

#reset-password .reset-password-container .right-text-container .center-texts .title-text {
    display: inline-block;
    padding-bottom: .5rem;
}

#reset-password .reset-password-container .right-text-container .center-texts .instructions {
    padding-top: 1.75rem;
    display: flex;
    justify-content: center;
    padding: 1.25rem 0;
}

#reset-password .reset-password-container .right-text-container .center-texts .instructions > * {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 .75rem;
}

#reset-password .reset-password-container .right-text-container .center-texts .instructions > * > * {
    padding: .35rem 0;
}

#reset-password .reset-password-container .right-text-container .center-texts .reset-form {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#reset-password .reset-password-container .right-text-container .center-texts .reset-form .form-group {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.25rem;
    width: 100%;
}

#reset-password .reset-password-container .right-text-container .center-texts .reset-form .form-group label {
    padding-bottom: .5rem;
    color: rgb(119, 118, 118);
    font-weight: 600;
}

#reset-password .reset-password-container .right-text-container .center-texts .reset-form .form-group input[type="password"] {
    font-size: 1rem;
    padding: .5rem .35rem;
    border: 1.5px solid rgb(9,98,238);
    border-radius: 2px;
    outline: none;
    width: 100%;
    color: rgb(119, 118, 118);
}

/* for smaller devices */
@media screen and (max-width: 900px) {
    #reset-password .reset-password-container {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 750px) {
    #reset-password .reset-password-container {
        display: flex;
        flex-direction: column;
    }

    #reset-password .reset-password-container > :first-child {
        order: 1;
    }

    #reset-password .reset-password-container > :last-child {
        order: 0;
    }
}

@media screen and (max-width: 500px) {
    #reset-password .reset-password-container .right-text-container .center-texts .reset-form {
        max-width: auto;
        /* margin: auto; */
    }

    #reset-password .reset-password-container .right-text-container {
        max-width: 350px;
        margin: auto;
    }

    #reset-password .reset-password-container .right-text-container .center-texts .reset-form .form-group input[type="password"] {
        font-size: .85rem;
    }

    #reset-password .reset-password-container .right-text-container .center-texts .instructions {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    #reset-password .reset-password-container .left-img-container .text-container .username {
        font-size: 1.5rem;
    }

    #reset-password .reset-password-container .left-img-container .text-container .user-quote {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 350px) {
    #reset-password .reset-password-container .right-text-container .center-texts .instructions {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    #reset-password .reset-password-container .left-img-container .text-container .user-quote {
        font-size: 1rem;
    }

    #reset-password .reset-password-container .left-img-container .text-container .username {
        font-size: 1.25rem;
    }
}